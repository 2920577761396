@import url('https://fonts.googleapis.com/css2?family=Playwrite+DK+Loopet:wght@100..400&display=swap');

@font-face {
  font-family: 'MiFuente';
  src: url('./fonts/VerilySerifMono.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuenteBoda';
  src: url('./fonts/Tempting\ -\ PERSONAL\ USE\ ONLY.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuenteNos';
  src: url('./fonts/Autography.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuenteFirma';
  src: url('./fonts/Longevity.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  background-color: #F4EEED;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.container-web {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.text-center {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;

}

.image-container-nosotros {
  position: relative;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(101, 119, 101, 0.5);
  transition: transform 0.2s, box-shadow 0.2s;
  
  flex-wrap: wrap;
  gap: 16px; /* Espacio entre imágenes */
  justify-content: center;
  margin: 0; /* Sin margen */
  padding: 0; /* Sin padding */



}

.image-container-galery {
  position: relative;
  display: inline-block;
  overflow: hidden;

}

.shadow-img {
  display: block;
  max-width: 100%;
  height: auto;
  mask-image: linear-gradient(to bottom, transparent, black 1%, black 50%, transparent);
  -webkit-mask-image: linear-gradient(to bottom, transparent, black 1%, black 50%, transparent);
}

.shadow-img:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.overlay-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  animation: moveDownUp 1s infinite;
  color: #97B1C0;
}

/* Animation for the arrow */
@keyframes moveDownUp {

  0%,
  100% {
    transform: translate(-50%, -50%);
  }

  50% {
    transform: translate(-50%, -0%);
  }
}

.custom-font-text {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #97B1C0;
  text-align: center;
  font-size: 30px;
}



.custom-font {
font-family: 'FuenteNos', cursive;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #97B1C0;
  text-align: center;
}

.custom-font-nombres {
  font-family: 'FuenteBoda';
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  font-size: 45px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #97B1C0;
  text-align: center;
}





.custom-font-p {
  font-family: 'MiFuente';
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-size: 16px;
}

.custom-font-firma {
  font-family: 'FuenteNos';
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-size: 40px;
  color: #97B1C0;
}

/*
.time-unit {
  text-align: center;
}

.time-value {
  font-size: 15px;
  font-weight: bold;
  background-color: #97B1C0;
  color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.time-label {
  font-size: 12px;
  color: #777;
}

.countdown-timer {
  background-color: #97B1C0;
  padding: 20px;
  width: 200px;
  text-align: center;
}
*/
.button {
  background-color: #97B1C0;
  /* Azul de Google */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #777;
}

.item {
  float: left;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.custom-label {
  font-size: 1px;
}

/*Galeria*/
/* Gallery.css */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
  float: left;
}

.gallery-image {
  width: 150px;
  height: 150px;
  object-fit: cover;

}

.image-viewer {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}



.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.controls button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.close {
  position: fixed;
  top: 30px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

/*-------------*/
.overlay-div button {
  background-color: #ffffff;
  /* Azul de Google */
  color: rgb(0, 0, 0);
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 80%;
  margin-bottom: 5%;
}

.overlay-div button:hover {
  background-color: #777;
}


/******************/


.overlay-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  /* Fondo blanco semi-transparente */
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*********************/
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator .line {
  flex-grow: 1;
  height: 2px;
  background-color: #EFD9D1;
}

.separator .icon {
  margin: 0 15px;
  font-size: 24px;
  color: #DDB7AB;
}
/****************************/
.itinerary-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative;
  font-family: 'Times New Roman', Times, serif;
}

.icon {
  font-size: 24px;
  color: #97B1C0; /* Color dorado */
  margin-right: 15px;
  margin-left: 5%;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2%;
}

.title {
  font-family: 'MiFuente';
  font-weight: bold;
  color: #99AAB6; /* Color dorado */
  font-size: 16px;
}

.time {
  font-family: 'MiFuente';
  font-size: 14px;
  color: #99AAB6; /* Color dorado */
  font-weight: bold;
}

.itinerary-item::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #97B1C0; /* Color dorado */
  border-radius: 50%;
}

.itinerary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  position: relative;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
}

.itinerary::before {
  content: '';
  position: absolute;
  left: 25px;
  top: 20px;
  bottom: 20px;
  width: 2px;
  background-color: #97B1C0; /* Color dorado */
}
/*  -------------------------------------------------------------------  */
.fecha-boda {
 
  align-items: center;
  justify-content: space-around;
  font-family: 'Times New Roman', serif;
  color: #99AAB6;
  background-color: transparent; /* Puedes cambiar el fondo según tu necesidad */
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
.divBlock{
  display: flex;
}

.dia, .numero, .hora {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linea {
  width: 100%;
  border-bottom: 1px solid #97B1C0;
  margin-bottom: 5px;
}

.dia span, .hora span {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'MiFuente';
}

.numero {
  margin-top: -15px;
  font-size: 50px;
  font-weight: bold;
  font-family: 'MiFuente';
}

.mes-ano {
  margin-top: -10px;
  font-size: 18px;
  font-weight: normal;
  font-family: 'MiFuente';  
  font-weight: bold;
}

.hora {
  font-size: 16px;
  font-weight: normal;
  font-family: 'MiFuente';
  
}
/**********************************************************************/
.divHoras {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'Times New Roman', serif;
  color: #97B1C0;
  background-color: transparent; /* Puedes cambiar el fondo según tu necesidad */
  padding: 30px;
  width: 100%;
  box-sizing: border-box;

}
.divB{
  border: 0px solid;
}
.divEspacio{
  font-size: 30px;
  font-weight: bold;
  color: #97B1C0;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.time-label {
  font-size: 13px;
  color: #000;
  font-family: 'MiFuente';
  
}

.countdown-timer {
  background-color: #97B1C0;
  padding: 20px;
  text-align: center;
  
}

.time-value {
  font-size: 20px;
  font-weight: bold;
  background-color: #97B1C0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5%;
 
}
.time-unit {
  text-align: center;
}